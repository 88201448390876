import React, { Component } from 'react';

import Helmet from './Helmet';

import FaviconURL from 'img-raw/logo-icon.png';

export default class CannyHelmet extends Component {
  render() {
    return (
      <Helmet
        link={[
          {
            href: FaviconURL,
            rel: 'shortcut icon',
          },
        ]}
        title="Canny"
      />
    );
  }
}
