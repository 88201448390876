import React from 'react';

import { Helmet as ReactHelmet } from 'react-helmet-async';

import publicConfig from 'common/core/publicConfig';

import TouchIconURL from 'img-raw/apple-touch-icon.png';
import FaviconURL from 'img-raw/logo-icon.png';

const FBAppID = __DEV__ ? '1606035092942076' : '1606018679610384';
const GoogleSiteVerificationID = 'hutYg6OBDqx9fLFmeEqY4o0gjWPw2rkFt3-Sp2wbMQc';

type SocialProps =
  | {
      description: string;
      title: string;
      image: string;
      url: string;
    }
  | {
      description?: string;
      title?: string;
      image?: never;
      url?: never;
    };

type Props = React.ComponentProps<typeof ReactHelmet> &
  SocialProps & {
    allowIndexing?: boolean;
    defaultIcons?: boolean;
  };

const Helmet = ({
  allowIndexing = true,
  base,
  children,
  defaultIcons = false,
  link,
  meta,
  script,
  title,
  description,
  image,
  url,
}: Props) => {
  const supportsIndexing = publicConfig('supportsSearchEngineIndexing') && allowIndexing;

  const props = {
    ...(base && { base }),
    link: link || [],
    meta: meta || [],
    script: script || [],
    ...(title && { title }),
  };

  if (!supportsIndexing) {
    props.meta.push({ name: 'robots', content: 'noindex,nofollow' });
  }

  if (defaultIcons) {
    props.link.push(
      {
        href: FaviconURL,
        rel: 'shortcut icon',
      },
      {
        href: TouchIconURL,
        rel: 'apple-touch-icon',
      }
    );
  }

  props.meta.push({
    property: 'fb:app_id',
    content: FBAppID,
  });
  props.meta.push({
    name: 'google-site-verification',
    content: GoogleSiteVerificationID,
  });

  if (description) {
    props.meta.push({ name: 'description', content: description });
  }

  if (image && title && description && url) {
    props.meta.push(
      { property: 'og:image', content: image },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'website' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@cannyHQ' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: image }
    );
  }

  return <ReactHelmet {...props}>{children}</ReactHelmet>;
};

export default Helmet;
